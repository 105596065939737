<script lang='ts'>
  import { onMount } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import { Params, ProgressPos, ProgressWif, TestWords, WifAnswers, WifTemplate } from '@/definitions/langoid'

  let templates: Record<number, WifTemplate[]> = {}
  let progressPos: Record<number, ProgressPos> = {}
  let progressWif: Record<number, ProgressWif> = {}
  let posList: string[] = []
  let selectedPos = 0
  let selectedWifTemplate: WifTemplate | undefined
  let testWords: TestWords[] = []
  let inputs: Record<number, string> = {}
  let sums: Record<number, number> = {}
  let finished = false
  let solutions: Record<number, {
      correct: boolean,
      wif: string
    }> = {},
    inflected: number[] = []

  onMount(() => {
    loadForms()
  })

  const loadForms = async () => {
    const data = await fetchData('learn/wordFormsLoad', {})
    templates = data.wifTemplates
    progressPos = data.progressPos
    progressWif = data.progressWif
    sums = data.sums
    inflected = data.inflected
    for (const i in templates) {
      if (templates[i].length > 1) posList.push(i)
    }
    posList = [...posList]
  }

  const selectPos = (pos: number) => {
    selectedPos = pos
    selectedWifTemplate = undefined
  }

  const selectWifTemplate = async (template: WifTemplate) => {
    if (template.learning_priority === 'no') {
      notifier.error($_('wordFormsPage.noNeedToLearn'))
      return
    } else if (template.learning_priority === 'ignore') {
      notifier.info($_('wordFormsPage.canBeIgnored'))
    } else if (template.learning_priority === 'duplicate') {
      notifier.info($_('wordFormsPage.duplicate'), { duration: 1e4 })
    }
    const data = await fetchData('learn/wordFormsChooseForm', { forms: [template.id], pos: selectedPos })
    selectedWifTemplate = template
    testWords = data.words
    for (const i in inputs) {
      const el = document.getElementById('word-' + i)
      if (el) {
        el.style.background = 'inherit'
      }
    }
    inputs = {}
  }

  const checkSolution = async () => {
    const params: Params = { answers: {}, pos: selectedPos }
    for (const i in inputs) {
      params.answers[i] = {} as WifAnswers
      if (selectedWifTemplate) {
        params.answers[i][selectedWifTemplate.id] = inputs[i]
      }
    }
    if (Object.keys(params.answers).length === 0) {
      notifier.warning($_('wordFormsPage.youMustWrite'))
      return
    }

    const data = await fetchData('learn/wordFormsCheckSolution', params)
    const answers: Record<number, WifAnswers> = data.wifAnswers
    solutions = {}
    for (const i in inputs) {
      const el = document.getElementById('word-' + i)
      if (answers[i]) {
        const correct: boolean = answers[i].wif === inputs[i]
        if (el) {
          el.style.background = correct ? 'green' : 'red'
        }
        solutions[i] = { correct, wif: answers[i].wif }
        updateCounters(correct)
      } else {
        const correct = true
        if (el) {
          el.style.background = correct ? 'green' : 'red'
        }
        solutions[i] = { correct, wif: '' }
        updateCounters(correct)
      }
    }
    finished = true
  }

  function updateCounters (correct: boolean) {
    if (selectedPos && progressPos[selectedPos]) {
      const posProgress = progressPos[selectedPos]
      if (correct) {
        const alreadyCorrect = posProgress.correct || 0
        const alreadyCorrect1000 = posProgress.correct_last_1000 || 0
        posProgress.correct_last_1000 = alreadyCorrect1000 + 1
        posProgress.correct = alreadyCorrect + 1
      } else {
        const alreadyWrong = posProgress.wrong_last_1000 || 0
        posProgress.wrong_last_1000 = alreadyWrong + 1
      }
    }
    if (selectedWifTemplate?.id && progressWif[selectedWifTemplate.id]) {
      const wifProgress = progressWif[selectedWifTemplate.id]
      if (correct) {
        const alreadyCorrect = wifProgress.correct || 0
        const alreadyCorrect1000 = wifProgress.correct_last_1000 || 0
        wifProgress.correct_last_1000 = alreadyCorrect1000 + 1
        wifProgress.correct = alreadyCorrect + 1
      } else {
        const alreadyWrong = wifProgress.wrong_last_1000 || 0
        wifProgress.wrong_last_1000 = alreadyWrong + 1
      }
    }
  }

  function loadMore (): void {
    if (selectedWifTemplate) {
      selectWifTemplate(selectedWifTemplate)
    }
    finished = false
    solutions = {}
  }

  function toName (form: string): string {
    return form.split('_').slice(1).join(' ')
  }

  function toNum (template: WifTemplate): string | 0 {
    if (template.learning_priority === 'no') return ''
    if (template.learning_priority === 'ignore') return ''
    if (template.learning_priority === 'duplicate') return ''
    return 0
  }

  const setHint = (e: MouseEvent, content: string) => {
    if (e.target) (e.target as HTMLSpanElement).textContent = content
  }
</script>

<div class='words-forms-page _gap24'>
  <div class='chooser-block' class:-hidden={selectedPos}>
    <h2>{$_('wordFormsPage.choosePos')}</h2>
    <div class='links-list'>
      <div>{$_('wordFormsPage.pos')}</div>
      <div>{$_('wordFormsPage.correct')}</div>
      <div>{$_('wordFormsPage.sum')}</div>
      <div>{$_('wordFormsPage.all')}</div>
      {#each inflected as pos}
        {@const progress = progressPos[pos]?.correct_last_1000}
        <div
          class='_primary-btn'
          class:-active={pos === selectedPos}
          role='button'
          tabindex='0'
          on:click={() => selectPos(pos)}
          on:keypress={() => {}}
        >{$_('pos.' + pos)}</div>
        <div>{progress || ''}</div>
        <div>{@html progress ? progressPos[pos]?.wrong_last_1000 + progress : ''}</div>
        <div>{sums[pos] || ''}</div>
      {/each}
    </div>
  </div>

  {#if selectedPos}
    <div class='chooser-block' class:-hidden={selectedWifTemplate?.id}>
      <button class='_tertiary-btn' type='button' on:click={() => { selectedPos = 0 }}>{$_('tasksPage.buttonBack')}</button>
      <h2>{$_('wordFormsPage.pos')}: {$_('pos.' + selectedPos)}</h2>
      <strong>{$_('wordFormsPage.chooseWif')}</strong>
      <div class='links-list -wifs'>
        <div>{$_('wordFormsPage.form')}</div>
        <div>{$_('wordFormsPage.correct')}</div>
        <div>{$_('wordFormsPage.sum')}</div>
        {#each templates[selectedPos] as template}
          {@const progress = progressWif[template.id]?.correct_last_1000}
          <div
            class='_primary-btn -{template.learning_priority}'
            class:-active={template.id === selectedWifTemplate?.id}
            role='button'
            tabindex='0'
            on:click={() => { selectWifTemplate(template) }}
            on:keypress={() => {}}
          >
            {template.translation_keys === '' ? toName(template.content) : template.translation_keys?.split('@@').map(e => $_('@.' + e)).join(' ') || ''}
          </div>
          <div>{progress || toNum(template)}</div>
          <div>{progress ? progressWif[template.id]?.wrong_last_1000 + progress : ''}</div>
        {/each}
      </div>
    </div>
  {/if}

  {#if selectedWifTemplate && Object.keys(selectedWifTemplate).length}
    <form action='' on:submit|preventDefault={checkSolution}>
      <button class='_tertiary-btn' type='button' on:click={() => { selectedWifTemplate = undefined }}>{$_('tasksPage.buttonBack')}</button>
      <strong>
        {$_('pos.' + selectedPos)} {toName(templates[selectedPos].find(el => el.id === selectedWifTemplate?.id)?.content || '')}
      </strong>
      {#each testWords as testWord}
        <div class='word-question-block'>
          <input id='word-{testWord.id_word}' class='input' type='text' bind:value={inputs[testWord.id_word]} />
          <span class='testWord'>{testWord.word}</span>
          {#if solutions[testWord.id_word]?.wif && !solutions[testWord.id_word].correct}
            <span
              class='hint'
              role='button'
              tabindex='0'
              on:click={e => { setHint(e, solutions[testWord.id_word]?.wif || '') }}
              on:keypress={() => {}}
            >{$_('wordFormsPage.showCorrectSolution')}</span>
          {/if}
        </div>
      {/each}
      <button disabled={finished} type='submit'>{$_('wordFormsPage.checkSolution')}</button>
      {#if finished}
        <button type='button' on:click={loadMore}>{$_('loadMore')}</button>
      {/if}
    </form>
  {/if}
</div>

<style lang='scss'>
  .chooser-block {
    &.-hidden {
      display: none
    }
  }

  .links-list {
    display: grid;
    grid-template-columns: 19.2rem 6rem 6rem 6rem;
    gap: 1rem;

    &.-wifs {
      grid-template-columns: 19.2rem 6rem 6rem;
    }

    > div {
      &.-active {
        color: var(--Gray-Lighter);
        background: var(--color-error);
      }

      &.-must {
        color: var(--Gray-Lighter);
        background: var(--Primary-Dark);
      }

      &.-can {
        color: var(--Gray-Lighter);
        background: var(--Primary-Medium);
      }

      &.-duplicate {
        background: var(--Primary-Ligther);
      }

      &.-ignore {
        background: var(--Gray-Light);
        cursor: default;
      }

      &.-no {
        background: var(--Gray-Light);
        cursor: default;
      }
    }
  }

  .word-question-block {
    > .testWord {
      display: inline-block;
      min-width: 9.6rem;
    }

    > .hint {
      display: inline-block;
      padding: 0.2rem 0.4rem;
      background: var(--Gray-Medium);
      border-radius: 0.2rem;
    }
  }
</style>
